<template>
  <b-table
      class="position-relative"
      :items="products"
      :fields="tableColumns"
      responsive
      primary-key="id"
      show-empty
      :tbody-tr-class="rowClass"
  >
    <!-- Column: User -->
    <template #cell(product)="data">
      <b-media vertical-align="center">
        <template #aside>
          <b-img-lazy
              v-if="index==0"
              v-for="(image,index) in data.item.images"
              :src="siteUrl+image.url"
              :key="image.id"
              rounded
              width="150"
              left class="mb-1 mb-sm-0"
          />
        </template>
        <span>{{ data.item.id }}</span>
        <h4>
          <b-badge v-if="data.item.brand">{{ data.item.brand.name }}</b-badge>
          {{ data.item.name }}
          <b-badge variant="danger" v-if="data.item.pivot">{{ data.item.pivot.multiple }}x</b-badge>
        </h4>
        <h6>{{ data.item.short_name }}</h6>
        <small class="text-muted">{{ data.item.product_uuid }}</small>
        <div v-if="data.item.bundle_products">
          <b-badge v-for="bundle in data.item.bundle_products" :key="bundle.id">{{ bundle.barcode }}</b-badge>
        </div>
      </b-media>
    </template>

    <template #cell(brand)="data">
      <div class="text-nowrap" v-if="data.item.brand">
        {{ data.item.brand.name }}
      </div>
      <div v-else>
        Buraya düzeltme gelecek
      </div>
    </template>

    <template #cell(price)="data">
      <div class="text-nowrap">

        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-button variant="outline-secondary" style="width: 100px">KDV</b-button>
          </b-input-group-prepend>
          <v-select
              v-model="data.item.vat_rate"
              :options="_vatRates"
              :reduce="item => item.value"
              input-id="vat_rate"
          />
        </b-input-group>

        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-button variant="outline-secondary" style="width: 100px">Alış (TL)</b-button>
          </b-input-group-prepend>
          <b-form-input
              type="number"
              placeholder="Ürün Fiyatı"
              v-model="data.item.purchase_price"
              :disabled="data.item.isBundle ? true : false"
          />
        </b-input-group>

        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-button variant="outline-secondary" style="width: 100px">Ek Maliyet (TL)</b-button>
          </b-input-group-prepend>
          <b-form-input
              type="number"
              placeholder="Ürün Fiyatı (Alış + Ek Maliyet)"
              v-model="data.item.additional_price"
              :disabled="data.item.isBundle ? true : false"
          />
        </b-input-group>

        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-button variant="outline-secondary" style="width: 100px">Min Satış Fiyatı (TL)</b-button>
          </b-input-group-prepend>
          <b-form-input
              type="number"
              placeholder="Trendyol satış fiyatı"
              v-model="data.item.min_price"
              :disabled="data.item.isBundle ? true : false"
          />
        </b-input-group>
        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-button variant="outline-secondary" style="width: 100px">Adet</b-button>
          </b-input-group-prepend>
          <b-form-input
              type="number"
              placeholder="Ürün Adedi"
              v-model="data.item.quantity"
              :disabled="data.item.isBundle ? true : false"
          />
        </b-input-group>
        <b-input-group size="sm">
          <b-input-group-prepend>
            <b-button variant="outline-secondary" style="width: 100px">Termin (Gün)</b-button>
          </b-input-group-prepend>
          <b-form-input
              type="number"
              placeholder="Termin Süresi"
              v-model="data.item.deadline"
              :disabled="data.item.isBundle ? true : false"
          />
        </b-input-group>


        <b-button
            size="sm"
            variant="primary"
            @click="productUpdate(data.item)"
        >Kaydet
        </b-button>
      </div>
    </template>

    <!-- Column: Status -->
    <template #cell(status)="data">

      <b-badge
          pill
          variant="success"
          class="text-capitalize"
      >
        {{ data.item.product_status_id }}
      </b-badge>

    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
      >

        <template #button-content>
          <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
          />
        </template>
        <b-dropdown-item>
          <feather-icon icon="FileTextIcon"/>
          <span class="align-middle ml-50">Detaylar</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'product-edit', params: { id: data.item.product_uuid } }">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Düzenle</span>
        </b-dropdown-item>

        <b-dropdown-item @click="searchTyProduct($event,'9dc0110d-a6a0-4f52-9f5e-d73d28a9ad34',data.item)">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">TY Ürün Ekle</span>
        </b-dropdown-item>

        <b-dropdown-item>
          <feather-icon icon="TrashIcon"/>
          <span class="align-middle ml-50">Sil</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>

  </b-table>
</template>

<script>
import vSelect from "vue-select";
import {mapGetters} from "vuex";

export default {
  name: 'ProductTable',
  components: {vSelect},
  props: {
    products: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    tableColumns: [
      {
        key: 'product',
        label: 'Ürün Bilgi',
        sortable: true,
      },
      {
        key: 'barcode',
        label: 'Barkod',
        sortable: true,
      },
      {
        key: 'brand',
        label: 'MARKA',
        sortable: true,
      },
      {
        key: 'price',
        sortable: true,
      },
      {
        key: 'weight',
        label: 'Gramaj',
        sortable: true,
      },
      {
        key: 'status',
        sortable: true,
      },
      {
        key: 'actions',
        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters('product', ['_vatRates']),
    siteUrl() {
      return process.env.VUE_APP_IMG_URL
    },
  },
  methods: {
    productUpdate(product) {
      this.isShow = true
      this.$store.dispatch('product/updateProduct', product)
          .then(res => {
            if (res.content) {
              product = res.content
            }
            this.isShow = false
          })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.isBundle) return 'table-success'
    },
    searchTyProduct(event, storeUuid, product) {
      this.$root.$emit('search-ty-products-event', {
        storeUuid,
        product,
      })
      this.$root.$emit('bv::show::modal', 'search-ty-products-modal', event.target)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
